<template>
  <div class="welcomeMain welcomeMainPos">
    <div class="welcomeImg">
      <v-img src="../assets/img/main.svg" aspect-ratio="1.7" contain></v-img>
    </div>
    <v-divider class="deliverMain" vertical></v-divider>
    <div class="welcomeForm">
      <div class="welcomePage">
        <div class="welcome-title">Добро пожаловать</div>
        <div class="welcome-title">{{ itemData.welcometext }}</div>
        <p class="description">
          {{ itemData.description }}
        </p>
        <div v-if="this.itemData.unAuth">
          <p class="description">
            Авто перенаправление на <br />систему входа через
            <span>{{ counterTime }}</span> секунд
          </p>
          <p class="description">Либо перейти сейчас:</p>
        </div>
        <div v-if="!itemData.isAdmin" class="welcomeEntry">
          <a class="homeLink" href="https://client.entrance.gomobile.ru/">
             <v-icon> mdi-home </v-icon>
            <span class="linkText">Вход</span>
          </a>
          <span class="loginlinks problemEntry" @click="dialog = true">
            Проблемы со входом?
          </span>
        </div>
        <div v-else>
          <GoogleSinginBtn />
          <a class="loginlinks toadminLink" href="/">На главную</a>
        </div>
        <div class="reloadBtnWrapper">
          <button class="reloadBtn" @click="reloadPage">
             <v-icon> mdi-cached </v-icon>
          </button>
        </div>
      </div>
      <v-dialog v-model="dialog" max-width="434px">
        <div class="confirmWraper">
          <div class="dialogClose" @click="dialog = false">
            <v-icon> mdi-close </v-icon>
          </div>
          <div class="modal-container">
            <div class="infoTitle">Проблемы со входом?</div>
            <p>
              Если вы не помните логин или пароль, обратитесь к своему
              аккаунт-менеджеру или напишите нам на почту:
            </p>
            <div class="infoTitle">
              <a
                href="mailto:clients@gomobile.ru?subject=Creative%20approval%20system%20-%20issue"
                >clients@gomobile.ru</a
              >
            </div>
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import GoogleSinginBtn from "./GoogleSinginBtn";
export default {
  components: {
    GoogleSinginBtn,
  },
  props: ["itemData"],
  data() {
    return {
      counterTime: 15,
      dialog: false,
    };
  },
  created() {
    if (this.itemData.unAuth) {
      let timer = setInterval(() => {
        this.counterTime--;
        if (this.counterTime <= 0) {
          clearInterval(timer);
          location.replace("https://client.entrance.gomobile.ru/");
        }
      }, 1200);
    }
  },
  methods: {
    reloadPage() {
      location.reload(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/css/welcomeuser.scss";
@import "../assets/css/info.scss";
</style>
